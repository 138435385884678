<template>
  <v-card class="pa-6" v-if="accounts">
    <v-card-title>
      <SearchBar :searchClass="search_class" @searching="searching($event)" />

      <v-spacer></v-spacer>

      <add-account />

      <v-btn rounded text color="primary" @click="export_accounts"
        ><v-icon>mdi-microsoft-excel</v-icon>download excel</v-btn
      >
      <!-- <span class="caption mr-2 mt-2" style="width: 170px">
        <input @change="onFileChange" type="file" label="Import Accounts" />
      </span>
      <v-btn
        small
        rounded
        color="primary"
        v-if="file != null"
        @click="upload_file"
        >upload</v-btn
      > -->
    </v-card-title>
    <span class="subtitle-2">Selected: {{ selected.length }}</span>
    <v-btn
      v-if="selected.length > 0"
      small
      rounded
      text
      color="primary"
      @click="print_selected"
      ><v-icon>mdi-printer</v-icon>print selected</v-btn
    >
    <v-data-table
      :headers="headers"
      :items="accounts"
      :search="keyword"
      show-select
      @input="get_selected"
      v-model="selected"
    >
      <template v-slot:[`item.pic`]="{ item }">
        <v-avatar size="30" @click="viewProfile(item)">
          <v-img
            v-if="item.profile_picture_url != ''"
            class="ma-2"
            :src="item.profile_picture_url"
          ></v-img>
          <v-img
            v-else
            class="ma-2"
            src="https://firebasestorage.googleapis.com/v0/b/campco-cims.appspot.com/o/Profile%20Pictures%2Fprofile.png?alt=media&token=e0a72788-6568-48cf-a1a1-572a80a4b91b"
          ></v-img>
        </v-avatar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon class="mx-1" color="primary">mdi-account</v-icon> -->
        <v-icon class="mx-1" color="blue" @click="edit_account(item.id)"
          >mdi-pencil</v-icon
        >
        <v-icon class="mx-1" color="red" @click="confirm_delete(item.id)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- EDIT ACCOUNT COMPONENT -->
    <edit-account v-if="account" />
    <add-payment :payable="selected_payable" v-if="add_payment_dialog" />
    <!-- ******* -->
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="submit"
        @cancel-event="close_confirm"
      />
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddAccount from "./AddAccount.vue";
import axios from "axios";
export default {
  components: {
    AddAccount,
    EditAccount: () => import("./EditAccount.vue"),
    ConfirmNotification: () => import("../ConfirmNotification.vue"),
    AddPayment: () => import("@/components/PaymentComponents/AddPayment.vue"),
  },
  data() {
    return {
      file: null,
      account_id: null,
      show_alert: false,
      selected_payable: null,
      alert_message: "Are you sure you want to delete this account?",
      search_class: "",
      keyword: "",
      filtered_accounts: "",
      selected: [],
      payables: [
        {
          name: "One-off",
          icon: "mdi-cart",
        },
        {
          name: "Subscription",
          icon: "mdi-playlist-check",
        },
        {
          name: "Existing Invoice",
          icon: "mdi-notebook-outline",
        },
        {
          name: "Billing",
          icon: "mdi-cash-lock",
        },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Profile",
          value: "pic",
          align: "start",
        },
        {
          text: "Account Name",
          value: "full_name",
        },
        {
          text: "Company Name",
          value: "company_name",
        },
        {
          text: "Address",
          value: "address",
        },
        {
          text: "Contact Number",
          value: "contact_number",
        },
        {
          text: "Email Address",
          value: "email",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      accounts: "account/accounts",
      add_payment_dialog: "dialog/add_payment_dialog",
      account: "account/account",
    }),
  },
  methods: {
    ...mapActions({
      change_loading: "dialog/change_loading",
      get_accounts: "account/get_accounts",
      get_account: "account/get_account",
      get_account_payables: "account/get_account_payables",
      delete_account: "account/delete_account",
      change_edit_account_dialog: "dialog/change_edit_account_dialog",
      edit_address: "dialog/edit_address",
      set_notification: "notification/set_notification",
      change_add_payment_dialog: "dialog/change_add_payment_dialog",
    }),
    export_accounts() {
      // this.change_loading();
      window.open("http://127.0.0.1:8000/accounts/export");
      // this.change_loading();
    },
    print_selected() {
      console.log("success");
    },
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    async upload_file() {
      console.log(this.file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      };

      let formData = new FormData();
      formData.append("accounts", this.file);
      this.change_loading();
      try {
        let response = await axios.post("accounts/import", formData, config);
        this.change_loading();
        this.set_notification({
          message: response.data.success,
          color: "primary",
          showing: true,
          icon: "mdi-check",
        });
      } catch (error) {
        this.change_loading();
        this.set_notification({
          message: "Error: " + error + "; Please contact system administrator",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
      }
    },
    get_selected(e) {
      this.filtered_accounts = e;
      console.log(e);
    },
    print_accounts() {
      console.log(this.filtered_accounts.length);
    },
    async edit_account(id) {
      this.change_loading();
      await this.get_account(id);
      this.edit_address(true);
      this.change_edit_account_dialog();
      this.change_loading();
    },
    confirm_delete(id) {
      this.account_id = id;
      this.show_alert = true;
    },
    close_confirm() {
      this.show_alert = false;
    },
    async viewProfile(item) {
      this.change_loading();
      await this.get_account(item.id);
      this.$router.push("/account/" + item.id);
      this.change_loading();
    },
    searching(item) {
      this.keyword = item;
    },
    async submit() {
      this.show_alert = false;
      this.change_loading();
      try {
        await this.delete_account(this.account_id);
        this.set_notification({
          message: "You have successfully deleted an account",
          color: "primary",
          showing: true,
          icon: "mdi-check",
        });
      } catch (error) {
        this.set_notification({
          message: "Error: " + error + "; Please contact system administrator",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
      }
      this.change_loading();
    },
  },
  async created() {
    if (!this.accounts) {
      this.change_loading();
      await this.get_accounts();
      this.change_loading();
    }
  },
};
</script>

<style></style>
