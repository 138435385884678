import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import SignIn from "../views/SignIn.vue";
import Dashboard from "../views/Dashboard.vue";
import Account from "../views/Account.vue";
import ChartOfAccount from "../views/ChartOfAccount.vue";
import AccountProfile from "../views/AccountProfile.vue";
import ProductService from "../views/ProductService.vue";
import Invoice from "../views/Invoice.vue";
import Bill from "../views/Bill.vue";
import InvoiceProfile from "../views/InvoiceProfile.vue";
import Subscription from "../views/Subscription.vue";
import Payment from "../views/Payment.vue";
import Report from "../views/Report.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Signin",
    component: SignIn,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        next({ name: "dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/accounts",
    name: "accounts",
    component: Account,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/account/:id",
    name: "accountprofile",
    component: AccountProfile,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/invoices",
    name: "invoices",
    component: Invoice,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/bills",
    name: "bills",
    component: Bill,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    component: Subscription,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: Report,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/invoices/:id",
    name: "invoiceprofile",
    component: InvoiceProfile,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/payments",
    name: "payments",
    component: Payment,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/chart_of_accounts",
    name: "chart_of_accounts",
    component: ChartOfAccount,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/product_services",
    name: "product_services",
    component: ProductService,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
