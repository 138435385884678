<template>
  <v-card class="pa-6" v-if="product_services">
    <v-card-title>
      <SearchBar :searchClass="search_class" @searching="searching($event)" />
      <v-spacer></v-spacer>
      <add-product-service />
    </v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :items="product_services"
      :items-per-page="10"
      :search="keyword"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-icon class="mx-1" color="blue" @click="edit_product_service(item)"
            >mdi-pencil</v-icon
          >
          <v-icon class="mx-1" color="red" @click="confirm_delete(item.id)"
            >mdi-delete</v-icon
          >
        </v-row>
      </template>
    </v-data-table>
    <edit-product-service />
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="submit"
        @cancel-event="close_confirm"
      />
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AddProductService from "./AddProductService.vue";
export default {
  components: {
    AddProductService,
    EditProductService: () => import("./EditProductService.vue"),
    ConfirmNotification: () => import("../ConfirmNotification.vue"),
  },
  data() {
    return {
      product_service_id: null,
      show_alert: false,
      alert_message: "Are you sure you want to delete this product/service?",
      search_class: "",
      keyword: "",
      headers: [
        { text: "Item Code", value: "item_code" },
        { text: "Item Name", value: "item_name" },
        { text: "Unit Price", value: "unit_price" },
        { text: "Unit of Measure", value: "unit_of_measure" },
        { text: "Account Title", value: "chart_of_account.account_title" },
        { text: "Cost Center", value: "cost_center" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      product_services: "product_service/product_services",
    }),
  },
  methods: {
    ...mapActions({
      change_loading: "dialog/change_loading",
      get_product_services: "product_service/get_product_services",
      delete_product_service: "product_service/delete_product_service",
      change_edit_product_service_dialog:
        "dialog/change_edit_product_service_dialog",
      set_product_service: "product_service/set_product_service",
      set_notification: "notification/set_notification",
    }),
    async edit_product_service(item) {
      this.change_loading();
      this.set_product_service(item);
      this.change_edit_product_service_dialog();
      this.change_loading();
    },
    confirm_delete(id) {
      this.product_service_id = id;
      this.show_alert = true;
    },
    close_confirm() {
      this.show_alert = false;
    },
    searching(item) {
      this.keyword = item;
    },
    async submit() {
      this.show_alert = false;
      this.change_loading();
      try {
        await this.delete_product_service(this.product_service_id);
        this.set_notification({
          message: "You have successfully deleted a product/service",
          color: "primary",
          showing: true,
          icon: "mdi-check",
        });
      } catch (error) {
        this.set_notification({
          message: "Error: " + error + "; Please contact system administrator",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
      }
      this.change_loading();
    },
  },
  async created() {
    if (!this.product_services) {
      this.change_loading();
      await this.get_product_services();
      this.change_loading();
    }
  },
};
</script>

<style></style>
