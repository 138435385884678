<template>
  <v-card flat class="ma-6">
    <view-title title="Report Management" />
    <v-card>
      <v-card-text>
        <v-tabs left v-model="tab">
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item">
            <v-card flat>
              <v-card-text>
                <invoices-report v-if="tab == 0" />
                <bills-report v-else-if="tab == 1" />
                <journal-report v-else-if="tab == 2" />
                <statement-of-financial-condition v-else-if="tab == 3" />
                <trial-balance-report v-else />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
// import SubscriptionsTable from "@/components/SubscriptionComponents/SubscriptionsTable.vue";
import ViewTitle from "@/components/ViewTitle.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    // SubscriptionsTable,
    ViewTitle,
    InvoicesReport: () =>
      import("@/components/ReportComponents/InvoicesReport.vue"),
    BillsReport: () => import("@/components/ReportComponents/BillsReport.vue"),
    JournalReport: () =>
      import("@/components/ReportComponents/JournalReport.vue"),
    StatementOfFinancialCondition: () =>
      import("@/components/ReportComponents/StatementOfFinancialCondition.vue"),
    TrialBalanceReport: () =>
      import("@/components/ReportComponents/TrialBalanceReport.vue"),
  },
  data() {
    return {
      tab: null,
      filter: "",
      items: [
        "Invoices",
        "Bills",
        "Journal",
        "Trial Balance",
        "Statement of Financial Condition",
        "Collections",
      ],
    };
  },
  computed: {
    ...mapGetters({
      subscriptions: "subscription/subscriptions",
    }),
  },
  watch: {
    tab(a, b) {
      switch (a) {
        case 0:
          this.filter = "";
          break;
        case 1:
          this.filter = "ACTIVE";
          break;
        case 2:
          this.filter = "INACTIVE";
          break;
        default:
          break;
      }
    },
  },
  watch: {
    tab(a, b) {
      console.log(a);
    },
  },
  methods: {
    ...mapActions({
      get_subscriptions: "subscription/get_subscriptions",
      change_loading: "dialog/change_loading",
    }),
  },
  async created() {
    if (!this.subscriptions) {
      this.change_loading();
      await this.get_subscriptions();
      this.change_loading();
    }
  },
};
</script>

<style></style>
