<template>
  <v-text-field
    flat
    dense
    clearable
    :class="searchClass"
    prepend-icon="mdi-magnify"
    placeholder="Type to search . . ."
    v-model="keyword"
  >
  </v-text-field>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["searchClass"],
  name: "SearchBar",
  data() {
    return {
      keyword: "",
    };
  },
  watch: {
    // whenever keyword changes, this function will run
    keyword(newKeyword, oldKeyword) {
      this.search_keyword(newKeyword);
    },
  },
  methods: {
    ...mapActions({
      //   searching: "search/searching"
    }),
    search_keyword(item) {
      this.$emit("searching", item);
    },
  },
};
</script>

<style></style>
