<template>
  <div>
    <v-btn text class="rounded-xl" color="primary" @click="add"
      ><v-icon>mdi-plus</v-icon> new</v-btn
    >
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card class="" elevation="" max-width="">
        <v-sheet color="primary" class="pa-5 white--text">
          <v-row class="pa-2">
            <h2>Add New Product/Service</h2>

            <v-spacer></v-spacer>
            <h2 class="overline">CIMS(Campco Internal Management System)</h2>
          </v-row></v-sheet
        >

        <v-card-text class="mt-10">
          <v-form ref="form">
            <!-- FIRST ROW -->

            <br />
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  prepend-icon="mdi-abacus"
                  label="Item Code"
                  v-model="form.item_code"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-text-field>

                <v-text-field
                  prepend-icon="mdi-rename-box"
                  label="Item Name"
                  v-model="form.item_name"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-text-field>

                <v-text-field
                  prepend-icon="mdi-cash"
                  label="Unit Price"
                  v-model="form.unit_price"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-tape-measure"
                  label="Unit of Measure"
                  v-model="form.unit_of_measure"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-text-field>
                <v-select
                  :items="centers"
                  item-text="cost_center"
                  prepend-icon="mdi-domain"
                  label="Cost Center"
                  v-model="form.cost_center"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-select>
                <v-select
                  v-if="chart_of_accounts"
                  :items="chart_of_accounts"
                  item-text="account_title"
                  item-value="id"
                  prepend-icon="mdi-account-details"
                  label="Account Title"
                  v-model="form.chart_of_account_id"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-right">
          <v-btn
            small
            color="accent"
            class="rounded-xl"
            :loading="submit_loading"
            @click="confirm_submit"
            >Submit</v-btn
          >
          <v-btn small color="warning" class="rounded-xl" @click="reset"
            >Reset</v-btn
          >
          <v-btn
            small
            color="grey"
            class="rounded-xl white--text"
            @click="close"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="submit"
        @cancel-event="close_confirm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmNotification from "@/components/ConfirmNotification.vue";
export default {
  components: {
    ConfirmNotification,
  },
  data() {
    return {
      centers: [
        "Internet/Cable",
        "Aquaculture",
        "PRDP Project",
        "Administrative",
      ],
      submit_loading: false,
      alert_message: "Are you sure you want to add this Product and Service?",
      show_alert: false,
      dialog: false,
      form: {
        item_code: "",
        item_name: "",
        unit_price: "",
        unit_of_measure: "",
        chart_of_account_id: "",
        cost_center: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      product_services: "product_service/product_services",
      chart_of_accounts: "chart_of_account/chart_of_accounts",
    }),
  },
  methods: {
    ...mapActions({
      add_product_service: "product_service/add_product_service",
      get_product_services: "product_service/get_product_services",
      set_notification: "notification/set_notification",
      change_loading: "dialog/change_loading",
      get_chart_of_accounts: "chart_of_account/get_chart_of_accounts",
    }),
    add() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    confirm_submit() {
      if (
        this.form.item_code == "" ||
        this.form.item_name == "" ||
        this.form.unit_price == "" ||
        this.form.unit_of_measure == "" ||
        this.form.chart_of_account_id == ""
      ) {
        window.alert("Please fill all Fields");
      } else {
        this.show_alert = true;
        this.submit_loading = true;
      }
    },
    async submit() {
      this.show_alert = false;
      this.dialog = false;
      this.change_loading();
      try {
        await this.add_product_service(this.form);
        this.submit_loading = false;
        this.set_notification({
          message: "You have successfully added a new product and service",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
      } catch (error) {
        this.submit_loading = false;
        this.set_notification({
          message: "Connection timeout. Please check your internet connection",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
      }
      this.change_loading();
      this.reset();
      this.dialog = false;
    },
    close_confirm() {
      this.show_alert = false;
      this.submit_loading = false;
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  async created() {
    if (!this.chart_of_accounts) {
      this.change_loading();
      try {
        await this.get_chart_of_accounts();
        this.change_loading();
      } catch (error) {
        this.change_loading();
        this.set_notification({
          message: "Connection timeout. Please check your internet connection",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
      }
    }
  },
};
</script>

<style></style>
