<template>
  <v-card flat class="ma-6">
    <view-title title="Subscription Management" />
    <v-card>
      <v-card-text>
        <v-tabs left v-model="tab">
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
          <v-spacer></v-spacer>
          <v-chip v-if="subscriptions" class="mr-2"
            >ALL: {{ subscriptions.length }}</v-chip
          >
          <v-chip v-if="subscriptions" class="mr-2"
            >ACTIVE: {{ get_active().length }}</v-chip
          >
          <v-chip v-if="subscriptions"
            >INACTIVE: {{ get_inactive().length }}</v-chip
          >
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item">
            <v-card flat>
              <v-card-text>
                <subscriptions-table :filter="filter" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import SubscriptionsTable from "@/components/SubscriptionComponents/SubscriptionsTable.vue";
import ViewTitle from "@/components/ViewTitle.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    SubscriptionsTable,
    ViewTitle,
  },
  data() {
    return {
      tab: null,
      filter: "",
      items: ["ALL", "ACTIVE", "INACTIVE"],
    };
  },
  computed: {
    ...mapGetters({
      subscriptions: "subscription/subscriptions",
    }),
  },
  watch: {
    tab(a, b) {
      switch (a) {
        case 0:
          this.filter = "";
          break;
        case 1:
          this.filter = "ACTIVE";
          break;
        case 2:
          this.filter = "INACTIVE";
          break;
        default:
          break;
      }
    },
  },
  methods: {
    ...mapActions({
      get_subscriptions: "subscription/get_subscriptions",
      change_loading: "dialog/change_loading",
    }),
    get_active() {
      let active = this.subscriptions.filter((s) => {
        return s.status == "ACTIVE";
      });
      return active;
    },
    get_inactive() {
      let inactive = this.subscriptions.filter((s) => {
        return s.status == "INACTIVE";
      });
      return inactive;
    },
  },
  async created() {
    if (!this.subscriptions) {
      this.change_loading();
      await this.get_subscriptions();
      this.change_loading();
    }
  },
};
</script>

<style></style>
