import axios from "axios";
export default {
  namespaced: true,
  state: {
    // state of app. can store anything from strings to objects
    token: null,
    user: null,
    // default_url: "http://127.0.0.1:8000/api",
    default_url: "https://campco-server.kpcms.online/api",
  },
  // read info from state
  getters: {
    authenticated(state) {
      return state.token && state.user;
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
    default_url(state) {
      return state.default_url;
    },
  },
  mutations: {
    // update the state
    SET_TOKEN(state, token) {
      state.token = token;
      window.localStorage.setItem("token", token);
    },
    SET_USER(state, data) {
      state.user = data;
    },
  },
  actions: {
    // make request to api but commit to mutations
    async signin({ dispatch }, credentials) {
      try {
        let response = await axios.post("login", credentials);
        return dispatch("attempt", response.data);
      } catch {
        let msg = {
          message: "Username or Password is Incorrect!",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        };
        dispatch("notification/set_notification", msg, { root: true });
      }
    },
    // attempt to access me api to check if token is valid
    // and give us back the users info
    async attempt({ commit, state }, token) {
      // if there is a token, commit
      if (token) {
        commit("SET_TOKEN", token);
      }
      // if there is no token in the sate, stop the execution
      if (!state.token) {
        return;
      }
      // check token if it is valid in me api
      try {
        // pass header for authorization
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        let response = await axios.get("auth/attempt");
        // if it is true, set_user to response
        commit("SET_USER", response.data);
        // get services
      } catch (e) {
        // if errors occurs set state to null
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },
    async signout({ commit }) {
      return await axios.post("logout").then(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      });
    },
  },
};
