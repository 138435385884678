import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VSnackbar,{attrs:{"value":"true","timeout":"-1","centered":"","shaped":"","color":_vm.color,"icon":_vm.icon,"border":"left","prominent":"","width":"650"}},[_c(VRow,[_c(VCol,{staticClass:"mt-4",attrs:{"cols":"1"}},[_c(VIcon,{attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.icon))])],1),_c(VCol,{attrs:{"cols":""}},[_c('p',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.message)+" ")])]),_c(VCol,{attrs:{"cols":"3"}},[_c(VBtn,{staticClass:"mb-2",attrs:{"color":"accent"},on:{"click":_vm.confirm}},[_vm._v("Yes")]),_c(VBtn,{attrs:{"color":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }