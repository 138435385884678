import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VImg,{staticStyle:{"height":"100vh"},attrs:{"src":require("@/assets/bryan_background2.jpg")}},[_c('div',{staticClass:"mt-8 pa-16"},[_c(VRow,[_c(VCol,{attrs:{"cols":"8"}},[_c(VImg,{attrs:{"src":require("@/assets/empowering.png"),"max-width":"90%"}}),_c('p',{staticClass:"caption white--text text--lighten-1 mt-8 text-center",staticStyle:{"background-color":"#038d02","padding":"7px","max-width":"500px","margin":"auto"}},[_vm._v(" Powered by: JMBComputers | Version 2.0 Background Image by: Bryan L. Arreo ")])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VCard,{staticClass:"elevation-12 rounded-l mt-16 pa-6",attrs:{"max-width":"500"}},[_c(VRow,[_c(VCol,{attrs:{"cols":""}},[_c('div',{staticClass:"mt-10 text-center"},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":require("@/assets/logo.jpg"),"max-width":"80%"}}),_c('br'),_c(VForm,{ref:"form"},[_c(VTextField,{staticClass:"rounded-xl",attrs:{"prepend-icon":"mdi-account-outline","label":"Username","placeholder":"Enter your username","autofocus":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c(VTextField,{staticClass:"rounded-xl",attrs:{"type":"password","prepend-icon":"mdi-lock-outline","label":"Password","placeholder":"Enter your password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c(VBtn,{staticClass:"mr-2",attrs:{"rounded":"","elevation":"2","color":"accent","loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Login")]),_c(VBtn,{attrs:{"rounded":"","elevation":"2","color":"secondary"},on:{"click":_vm.reset}},[_vm._v("Reset")]),_c('p',{staticClass:"caption primary--text mt-5"},[_vm._v(" Forgot your password? ")])],1)])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }