import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: "#55a630", //green
        primary: "#118002",
        secondary: "#bfd200",
        tertiary: "#039BE5",
        accent: "#007f5f",
        success: "#2b9348",
        error: "#ff0000",
        warning: "#d4d700",
        grey: "464646",
      },
    },
  },
});
