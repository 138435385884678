<template>
  <v-card flat class="ma-6">
    <view-title title="Bills Management" />
    <v-card>
      <v-card-text>
        <v-tabs left v-model="tab">
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item">
            <v-card flat>
              <v-card-text>
                <invoices-table :filter="filter" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import InvoicesTable from "@/components/InvoiceComponents/InvoicesTable.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    InvoicesTable,
  },
  data() {
    return {
      tab: null,
      filter: "",
      items: ["ALL", "UNPAID", "PAID"],
    };
  },
  computed: {
    ...mapGetters({
      invoices: "invoice/invoices",
      accounts: "account/accounts",
      product_services: "product_service/product_services",
    }),
    bills() {
      let items = null;
      let filtered = this.invoices.filter((i) => {
        return i.is_quote == 0;
      });
      items = filtered;
      return items;
    },
  },
  watch: {
    tab(a, b) {
      switch (a) {
        case 0:
          this.filter = "";
          break;
        case 1:
          this.filter = "UNPAID";
          break;
        case 2:
          this.filter = "PAID";
          break;
        default:
          break;
      }
    },
  },
  methods: {
    ...mapActions({
      get_invoices: "invoice/get_invoices",
      get_accounts: "account/get_accounts",
      get_product_services: "product_service/get_product_services",
      change_loading: "dialog/change_loading",
    }),
  },
  async created() {
    if (!this.accounts || !this.product_services || !this.invoices) {
      this.change_loading();
      if (!this.invoices) await this.get_invoices();
      if (!this.accounts) await this.get_accounts();
      if (!this.product_services) await this.get_product_services();
      this.change_loading();
    }
  },
};
</script>

<style></style>
