import axios from "axios";

// import axios from "axios";
export default {
  namespaced: true,
  state: {
    payment_report: null,
    invoice_report: null,
  },
  getters: {
    payment_report(state) {
      return state.payment_report;
    },
    invoice_report(state) {
      return state.invoice_report;
    },
  },
  mutations: {
    SET_PAYMENT_REPORT(state, payment_report) {
      state.payment_report = payment_report;
    },
    SET_INVOICE_REPORT(state, invoice_report) {
      state.invoice_report = invoice_report;
    },
  },
  actions: {
    async get_payment_report({ commit }, date) {
      let response = await axios.get("/payment_report/" + JSON.stringify(date));
      console.log(response.data);
      commit("SET_PAYMENT_REPORT", response.data);
    },
    async get_invoice_report({ commit }, date) {
      let response = await axios.get("/invoice_report/" + JSON.stringify(date));
      console.log(response.data);
      commit("SET_INVOICE_REPORT", response.data);
    },
  },
};
