<template>

    <h1 class="mb-4 primary--text display-2 font-weight-bold">
        {{title}}
    </h1>

</template>

<script>
export default {
props: ['title']
}
</script>

<style>

</style>
