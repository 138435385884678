<template>
  <v-img style="height: 100vh" src="@/assets/bryan_background2.jpg">
    <div class="mt-8 pa-16">
      <!-- <v-container class="fill-height mt-13" fluid> -->
      <v-row>
        <v-col cols="8" class="">
          <v-img class="" src="@/assets/empowering.png" max-width="90%"></v-img>
          <p
            class="caption white--text text--lighten-1 mt-8 text-center"
            style="
              background-color: #038d02;
              padding: 7px;
              max-width: 500px;
              margin: auto;
            "
          >
            Powered by: JMBComputers | Version 2.0 Background Image by: Bryan L.
            Arreo
          </p>
        </v-col>
        <v-col cols="4" class="">
          <v-card class="elevation-12 rounded-l mt-16 pa-6" max-width="500">
            <v-row>
              <v-col cols="">
                <div class="mt-10 text-center">
                  <v-img
                    class="mx-auto"
                    src="@/assets/logo.jpg"
                    max-width="80%"
                  ></v-img>
                  <!-- <h1 class="grey--text">Sign in here</h1> -->
                  <br />
                  <v-form ref="form">
                    <v-text-field
                      class="rounded-xl"
                      prepend-icon="mdi-account-outline"
                      label="Username"
                      placeholder="Enter your username"
                      v-model="form.username"
                      autofocus
                    ></v-text-field>
                    <v-text-field
                      type="password"
                      class="rounded-xl"
                      prepend-icon="mdi-lock-outline"
                      label="Password"
                      placeholder="Enter your password"
                      v-model="form.password"
                    >
                    </v-text-field>
                  </v-form>
                  <v-btn
                    rounded
                    elevation="2"
                    color="accent"
                    class="mr-2"
                    @click="submit()"
                    :loading="loading"
                  >
                    Login</v-btn
                  >
                  <v-btn rounded elevation="2" color="secondary" @click="reset"
                    >Reset</v-btn
                  >
                  <p class="caption primary--text mt-5">
                    Forgot your password?
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- </v-container> -->
    </div>
  </v-img>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      signin: "auth/signin",
      set_notification: "notification/set_notification",
    }),
    reset() {
      (this.form.username = ""), (this.form.password = "");
    },
    async submit() {
      this.loading = true;
      try {
        await this.signin(this.form);
        this.loading = false;
        this.$router.push("/accounts");
      } catch (error) {
        if (err.response.status == 401) {
          this.set_notification({
            message: "Username or Password Incorrect. Please try again.",
            color: "red",
            showing: true,
            icon: "mdi-alert",
          });
        } else {
          this.set_notification({
            message: "Something went wrong. Please contact the administrator",
            color: "red",
            showing: true,
            icon: "mdi-alert",
          });
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
