// import axios from "axios";
export default {
  namespaced: true,
  state: {
    loading: false,
    edit_account_dialog: false,
    edit_chart_of_account_dialog: false,
    edit_product_service_dialog: false,
    add_invoice_dialog: false,
    add_invoice_item_dialog: false,
    confirmation: false,
    notification: false,
    address_picker: false,
    add_subscription_dialog: false,
    edit_subscription_dialog: false,
    add_subscription_payment_dialog: false,
    subscription_info_dialog: false,
    subscription_payment_history_dialog: false,
    invoice_info_dialog: false,
    subscription_confirmation_dialog: false,
    add_payment_dialog: false,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    edit_account_dialog(state) {
      return state.edit_account_dialog;
    },
    edit_chart_of_account_dialog(state) {
      return state.edit_chart_of_account_dialog;
    },
    edit_product_service_dialog(state) {
      return state.edit_product_service_dialog;
    },
    add_invoice_dialog(state) {
      return state.add_invoice_dialog;
    },
    add_invoice_item_dialog(state) {
      return state.add_invoice_item_dialog;
    },
    address_picker(state) {
      return state.address_picker;
    },
    add_subscription_dialog(state) {
      return state.add_subscription_dialog;
    },
    edit_subscription_dialog(state) {
      return state.edit_subscription_dialog;
    },
    add_subscription_payment_dialog(state) {
      return state.add_subscription_payment_dialog;
    },
    subscription_info_dialog(state) {
      return state.subscription_info_dialog;
    },
    subscription_payment_history_dialog(state) {
      return state.subscription_payment_history_dialog;
    },
    invoice_info_dialog(state) {
      return state.invoice_info_dialog;
    },
    subscription_confirmation_dialog(state) {
      return state.subscription_confirmation_dialog;
    },
    add_payment_dialog(state) {
      return state.add_payment_dialog;
    },
  },
  mutations: {
    CHANGE_LOADING(state) {
      state.loading = !state.loading;
    },
    CHANGE_EDIT_ACCOUNT_DIALOG(state) {
      state.edit_account_dialog = !state.edit_account_dialog;
    },
    CHANGE_EDIT_CHART_OF_ACCOUNT_DIALOG(state) {
      state.edit_chart_of_account_dialog = !state.edit_chart_of_account_dialog;
    },
    CHANGE_EDIT_PRODUCT_SERVICE_DIALOG(state) {
      state.edit_product_service_dialog = !state.edit_product_service_dialog;
    },
    CHANGE_ADD_INVOICE_DIALOG(state) {
      state.add_invoice_dialog = !state.add_invoice_dialog;
    },
    CHANGE_ADD_INVOICE_ITEM_DIALOG(state) {
      state.add_invoice_item_dialog = !state.add_invoice_item_dialog;
    },
    CHANGE_ADD_SUBSCRIPTION_DIALOG(state) {
      state.add_subscription_dialog = !state.add_subscription_dialog;
    },
    CHANGE_EDIT_SUBSCRIPTION_DIALOG(state) {
      state.edit_subscription_dialog = !state.edit_subscription_dialog;
    },
    CHANGE_ADD_SUBSCRIPTION_PAYMENT_DIALOG(state) {
      state.add_subscription_payment_dialog =
        !state.add_subscription_payment_dialog;
    },
    CHANGE_SUBSCRIPTION_INFO_DIALOG(state) {
      state.subscription_info_dialog = !state.subscription_info_dialog;
    },
    CHANGE_SUBSCRIPTION_PAYMENT_HISTORY_DIALOG(state) {
      state.subscription_payment_history_dialog =
        !state.subscription_payment_history_dialog;
    },
    CHANGE_SUBSCRIPTION_CONFIRMATION_DIALOG(state) {
      state.subscription_confirmation_dialog =
        !state.subscription_confirmation_dialog;
    },
    CHANGE_INVOICE_INFO_DIALOG(state) {
      state.invoice_info_dialog = !state.invoice_info_dialog;
    },
    EDIT_ADDRESS(state) {
      state.address_picker = !state.address_picker;
    },
    CHANGE_ADD_PAYMENT_DIALOG(state) {
      state.add_payment_dialog = !state.add_payment_dialog;
    },
  },
  actions: {
    change_loading({ commit }) {
      commit("CHANGE_LOADING");
    },
    change_edit_account_dialog({ commit }) {
      commit("CHANGE_EDIT_ACCOUNT_DIALOG");
    },
    change_edit_chart_of_account_dialog({ commit }) {
      commit("CHANGE_EDIT_CHART_OF_ACCOUNT_DIALOG");
    },
    change_edit_product_service_dialog({ commit }) {
      commit("CHANGE_EDIT_PRODUCT_SERVICE_DIALOG");
    },
    change_add_invoice_dialog({ commit }) {
      commit("CHANGE_ADD_INVOICE_DIALOG");
    },
    change_add_invoice_item_dialog({ commit }) {
      commit("CHANGE_ADD_INVOICE_ITEM_DIALOG");
    },
    edit_address({ commit }) {
      commit("EDIT_ADDRESS");
    },
    change_add_subscription_dialog({ commit }) {
      commit("CHANGE_ADD_SUBSCRIPTION_DIALOG");
    },
    change_edit_subscription_dialog({ commit }) {
      commit("CHANGE_EDIT_SUBSCRIPTION_DIALOG");
    },
    change_add_subscription_payment_dialog({ commit }) {
      commit("CHANGE_ADD_SUBSCRIPTION_PAYMENT_DIALOG");
    },
    change_subscription_info_dialog({ commit }) {
      commit("CHANGE_SUBSCRIPTION_INFO_DIALOG");
    },
    change_subscription_payment_history_dialog({ commit }) {
      commit("CHANGE_SUBSCRIPTION_PAYMENT_HISTORY_DIALOG");
    },
    change_subscription_confirmation_dialog({ commit }) {
      commit("CHANGE_SUBSCRIPTION_CONFIRMATION_DIALOG");
    },
    change_invoice_info_dialog({ commit }) {
      commit("CHANGE_INVOICE_INFO_DIALOG");
    },
    change_add_payment_dialog({ commit }) {
      commit("CHANGE_ADD_PAYMENT_DIALOG");
    },
  },
};
