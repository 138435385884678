<template>
  <div>
    <v-snackbar
      :value="notification.showing"
      shaped
      centered
      :color="notification.color"
    >
      <div class="text-center">
        <v-icon class="pr-2">{{ notification.icon }}</v-icon
        >{{ this.notification.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return { snackbar: true };
  },
  computed: {
    ...mapState("notification", ["notification"]),
  },
};
</script>

<style></style>
