import axios from "axios";

export default {
  namespaced: true,
  state: {
    payments: null,
    payment: null,
  },
  getters: {
    payments(state) {
      return state.payments;
    },
    payment(state) {
      return state.payment;
    },
  },
  mutations: {
    SET_PAYMENTS(state, payments) {
      state.payments = payments;
    },
    SET_PAYMENT(state, payment) {
      state.payment = payment;
    },
    ADD_PAYMENT(state, payment) {
      state.payments.unshift(payment);
    },
    EDIT_PAYMENT(state, payment) {
      let id = state.payments.findIndex((a) => {
        return a.id == payment.id;
      });
      state.payments.splice(id, 1, payment);
      state.payment = payment;
    },
    DELETE_PAYMENT(state, payment) {
      let id = state.payments.findIndex((a) => {
        return a.id == payment.id;
      });
      state.payments.splice(id, 1);
    },
  },
  actions: {
    async get_payments({ commit }) {
      let response = await axios.get("/payments");
      console.log(response.data);
      commit("SET_PAYMENTS", response.data);
    },
    async next_page_payments({ commit }, url) {
      let response = await axios.get(
        "" + "payments" + url.slice(url.indexOf("?"))
      );
      commit("SET_PAYMENTS", response.data);
    },
    async previous_page_payments({ commit }, url) {
      let response = await axios.get(
        "" + "payments" + url.slice(url.indexOf("?"))
      );
      commit("SET_PAYMENTS", response.data);
    },
    async get_current_page_payments({ commit }, page) {
      let response = await axios.get("" + "payments?page=" + page);
      commit("SET_PAYMENTS", response.data);
    },
    async get_payment({ commit }, id) {
      let response = await axios.get("/payment/" + id);
      commit("SET_PAYMENT", response.data);
    },
    async add_payment({ commit }, payment) {
      let response = await axios.post("/payment", payment);
      commit("SET_PAYMENTS", response.data);
    },
    async edit_payment({ commit }, payment) {
      let response = await axios.put("/payment", payment);
      commit("EDIT_PAYMENT", response.data);
    },
    async delete_payment({ commit }, id) {
      let response = await axios.delete("/payment/" + id);
      commit("DELETE_PAYMENT", response.data);
    },

    async delete_subscription({ commit, state }, id) {
      let response = await axios.delete(
        "/subscription/" + state.payment.id + "/" + id
      );
      commit("EDIT_PAYMENT", response.data);
    },
  },
};
