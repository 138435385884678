import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import dialog from "./dialog";
import account from "./account";
import notification from "./notification";
import chart_of_account from "./chart_of_account";
import product_service from "./product_service";
import search from "./search";
import invoice from "./invoice";
import subscription from "./subscription";
import payment from "./payment";
import report from "./report";
import push_notification from "./push_notification";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    dialog,
    account,
    notification,
    chart_of_account,
    product_service,
    search,
    invoice,
    subscription,
    payment,
    push_notification,
    report,
  },
});
