import axios from "axios";

// import axios from "axios";
export default {
  namespaced: true,
  state: {
    chart_of_accounts: null,
    chart_of_account: null,
  },
  getters: {
    chart_of_accounts(state) {
      return state.chart_of_accounts;
    },
    chart_of_account(state) {
      return state.chart_of_account;
    },
  },
  mutations: {
    SET_CHART_OF_ACCOUNTS(state, chart_of_accounts) {
      state.chart_of_accounts = chart_of_accounts;
    },
    SET_CHART_OF_ACCOUNT(state, chart_of_account) {
      state.chart_of_account = chart_of_account;
    },
    ADD_CHART_OF_ACCOUNT(state, chart_of_account) {
      state.chart_of_accounts.unshift(chart_of_account);
    },
    EDIT_CHART_OF_ACCOUNT(state, chart_of_account) {
      let id = state.chart_of_accounts.findIndex((a) => {
        return a.id == chart_of_account.id;
      });
      state.chart_of_accounts.splice(id, 1, chart_of_account);
    },
    DELETE_CHART_OF_ACCOUNT(state, chart_of_account) {
      let id = state.chart_of_accounts.findIndex((a) => {
        return a.id == chart_of_account.id;
      });
      state.chart_of_accounts.splice(id, 1);
    },
  },
  actions: {
    async add_chart_of_account({ commit }, request) {
      let response = await axios.post("/chart_of_account", request);
      commit("ADD_CHART_OF_ACCOUNT", response.data);
      console.log(response.data);
    },
    async edit_chart_of_account({ commit }, chart_of_account) {
      let response = await axios.put("/chart_of_account", chart_of_account);
      commit("EDIT_CHART_OF_ACCOUNT", response.data);
    },
    async delete_chart_of_account({ commit }, id) {
      let response = await axios.delete("/chart_of_account/" + id);
      commit("DELETE_CHART_OF_ACCOUNT", response.data);
    },
    async get_chart_of_accounts({ commit }) {
      let response = await axios.get("/chart_of_accounts");
      commit("SET_CHART_OF_ACCOUNTS", response.data);
    },
    async set_chart_of_account({ commit }, chart_of_account) {
      commit("SET_CHART_OF_ACCOUNT", chart_of_account);
    },
  },
};
