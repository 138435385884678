<template>
  <v-card class="pa-6" v-if="chart_of_accounts">
    <v-card-title>
      <SearchBar :searchClass="search_class" @searching="searching($event)" />
      <!-- <v-text-field
        placeholder="Type to search . . ."
        autofocus
        v-model="search"
        append-icon="mdi-magnify"
      ></v-text-field> -->
      <v-spacer></v-spacer>
      <add-chart-of-account />
    </v-card-title>

    <v-data-table
      dense
      :headers="headers"
      :items="chart_of_accounts"
      :items-per-page="10"
      :search="keyword"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-icon class="mx-1" color="blue" @click="edit_chart_of_account(item)"
            >mdi-pencil</v-icon
          >
          <v-icon class="mx-1" color="red" @click="confirm_delete(item.id)">mdi-delete</v-icon>
        </v-row>
      </template>
    </v-data-table>
    <edit-chart-of-account />
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="submit"
        @cancel-event="close_confirm"
      />
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddChartOfAccount from "./AddChartOfAccount.vue";
export default {
  components: {
    AddChartOfAccount,
    EditChartOfAccount: () => import("./EditChartOfAccount.vue"),
    ConfirmNotification: () => import("../ConfirmNotification.vue")
  },
  data() {
    return {
      chart_of_account_id: null,
      search_class: "",
      show_alert: false,
      alert_message: "Are you sure you want to delete this chart of account?",
      keyword: "",
      headers: [
        { text: "Account Title", value: "account_title" },
        { text: "FS Caption", value: "fs_caption" },
        { text: "Notes to FS", value: "notes_to_fs" },
        { text: "Income Statements", value: "income_statement" },
        { text: "Remarks", value: "remarks" },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      chart_of_accounts: "chart_of_account/chart_of_accounts",
    }),
  },
  methods: {
    ...mapActions({
      change_loading: "dialog/change_loading",
      get_chart_of_accounts: "chart_of_account/get_chart_of_accounts",
      get_chart_of_account: "chart_of_account/get_chart_of_account",
      delete_chart_of_account: "chart_of_account/delete_chart_of_account",
      change_edit_chart_of_account_dialog:
        "dialog/change_edit_chart_of_account_dialog",
      set_chart_of_account: "chart_of_account/set_chart_of_account",
      set_notification: "notification/set_notification"
    }),
    async edit_chart_of_account(item) {
      this.change_loading();
      await this.set_chart_of_account(item);
      this.change_edit_chart_of_account_dialog();
      this.change_loading();
    },
    confirm_delete(id) {
      this.chart_of_account_id = id;
      this.show_alert = true;
    },
    close_confirm() {
      this.show_alert = false;
    },
    searching(item) {
      this.keyword = item;
    },
    async submit(){
      this.show_alert = false;
      this.change_loading();
      try {
        await this.delete_chart_of_account(this.chart_of_account_id);
        this.set_notification({
            message:
              "You have successfully deleted an account",
            color: "primary",
            showing: true,
            icon: "mdi-check",
          });
      } catch (error) {
        this.set_notification({
            message:
              "Error: " + error + "; Please contact system administrator",
            color: "red",
            showing: true,
            icon: "mdi-alert",
          });
      }
      this.change_loading()
    },
  },
  async created() {
    if (!this.chart_of_accounts) {
      this.change_loading();
      await this.get_chart_of_accounts();
      this.change_loading();
    }
  },
};
</script>

<style></style>
