<template>
  <v-card flat class="pa-6" v-if="invoices">
    <v-card-title>
      <SearchBar :searchClass="search_class" @searching="searching($event)" />
      <v-spacer></v-spacer>
      <v-btn text rounded @click="add" color="primary" dark>
        <v-icon dark>mdi-plus</v-icon>new
      </v-btn>
      <v-btn text color="primary" rounded>
        <v-icon>mdi-chart-bar</v-icon> report
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :search="keyword"
        :items="filtered_invoices"
        :headers="headers"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="view_invoice(item)" color="primary" small rounded>
            <v-icon>mdi-file-document-arrow-right</v-icon>
          </v-btn>
          <v-btn icon @click="confirm_delete(item.id)">
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.full_name`]="{ item }">
          <span>
            {{ item.invoiceable.account.full_name }}
          </span>
        </template>
        <template v-slot:[`item.address`]="{ item }">
          <span v-if="item.invoiceable.account.address"
            >PUROK-{{ item.invoiceable.account.address }}</span
          >
        </template>
      </v-data-table>
    </v-card-text>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="submit"
        @cancel-event="close_confirm"
      />
    </div>
    <invoice-information v-if="invoice" />
    <add-invoice-item />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["filter"],
  components: {
    ConfirmNotification: () => import("../ConfirmNotification.vue"),
    InvoiceInformation: () =>
      import(
        "@/components/AccountComponents/AccountProfileComponents/AccountInvoiceComponents/InvoiceInformation.vue"
      ),
    AddInvoiceItem: () =>
      import(
        "@/components/AccountComponents/AccountProfileComponents/AccountInvoiceComponents/AddInvoiceItem.vue"
      ),
  },
  data() {
    return {
      invoice_id: "",
      show_alert: false,
      alert_message: "Are you sure you want to delete this invoice?",
      search_class: "",
      keyword: "",
      headers: [
        { text: "Invoice #", value: "id" },
        { text: "Account Name", value: "full_name" },
        { text: "Address", value: "address" },
        { text: "Amount", value: "amount" },
        { text: "Bill Date", value: "bill_date" },
        { text: "Due Date", value: "due_date" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      invoices: "invoice/invoices",
      invoice: "invoice/invoice",
      accounts: "account/accounts",
    }),
    filtered_invoices() {
      let filtered = null;
      if (this.filter == "") filtered = this.invoices;
      else
        filtered = this.invoices.filter((i) => {
          return i.status == this.filter;
        });
      return filtered;
    },
  },
  methods: {
    ...mapActions({
      change_add_invoice_dialog: "dialog/change_add_invoice_dialog",
      change_invoice_info_dialog: "dialog/change_invoice_info_dialog",
      get_invoice: "invoice/get_invoice",
      delete_invoice: "invoice/delete_invoice",
      change_loading: "dialog/change_loading",
      set_notification: "notification/set_notification",
    }),

    async view_invoice(item) {
      this.change_loading();
      await this.get_invoice(item.id);
      this.change_loading();
      this.change_invoice_info_dialog();
    },
    searching(item) {
      this.keyword = item;
    },
    confirm_delete(id) {
      this.invoice_id = id;
      this.show_alert = true;
    },
    async submit() {
      this.show_alert = false;
      this.change_loading();
      try {
        await this.delete_invoice(this.invoice_id);
        this.set_notification({
          message: "You have successfully deleted an invoice",
          color: "primary",
          showing: true,
          icon: "mdi-check",
        });
      } catch (error) {
        this.set_notification({
          message: "Error: " + error + "; Please contact system administrator",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
      }
      this.change_loading();
    },
    add() {
      this.change_add_invoice_dialog();
    },
    close_confirm() {
      this.show_alert = false;
    },
  },
};
</script>

<style></style>
