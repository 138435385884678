import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.invoices)?_c(VCard,{staticClass:"pa-6",attrs:{"flat":""}},[_c(VCardTitle,[_c('SearchBar',{attrs:{"searchClass":_vm.search_class},on:{"searching":function($event){return _vm.searching($event)}}}),_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":"","color":"primary","dark":""},on:{"click":_vm.add}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v("new ")],1),_c(VBtn,{attrs:{"text":"","color":"primary","rounded":""}},[_c(VIcon,[_vm._v("mdi-chart-bar")]),_vm._v(" report ")],1)],1),_c(VCardText,[_c(VDataTable,{attrs:{"search":_vm.keyword,"items":_vm.filtered_invoices,"headers":_vm.headers},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c(VBtn,{attrs:{"icon":"","color":"primary","small":"","rounded":""},on:{"click":function($event){return _vm.view_invoice(item)}}},[_c(VIcon,[_vm._v("mdi-file-document-arrow-right")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.confirm_delete(item.id)}}},[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}},{key:`item.full_name`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.invoiceable.account.full_name)+" ")])]}},{key:`item.address`,fn:function({ item }){return [(item.invoiceable.account.address)?_c('span',[_vm._v("PUROK-"+_vm._s(item.invoiceable.account.address))]):_vm._e()]}}],null,true)})],1),(_vm.show_alert)?_c('div',[_c('confirm-notification',{staticClass:"alert",attrs:{"icon":"mdi-alert-outline","type":"warning","message":this.alert_message},on:{"confirm-event":_vm.submit,"cancel-event":_vm.close_confirm}})],1):_vm._e(),(_vm.invoice)?_c('invoice-information'):_vm._e(),_c('add-invoice-item')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }