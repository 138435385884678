<template>
  <v-card flat class="ma-6">
    <view-title title="Chart Of Accounts Management"/>
    <chart-of-accounts-table />
  </v-card>
</template>

<script>
import ChartOfAccountsTable from "@/components/ChartOfAccountComponents/ChartOfAccountsTable.vue"
import ViewTitle from "@/components/ViewTitle.vue"
export default {
    components:{
        ChartOfAccountsTable,
        ViewTitle
    }
}
</script>

<style>

</style>