<template>
  <div>
    <v-btn text class="rounded-xl" color="primary" @click="add">
      <v-icon>mdi-plus</v-icon> new</v-btn
    >
    <v-dialog v-model="dialog" max-width="1200" persistent>
      <v-card class="" elevation="" max-width="">
        <v-card-title class="primary pa-5 white--text">
          <v-row class="pa-2">
            <h2 class="title">Add New Account</h2>

            <v-spacer></v-spacer>
            <h2 class="overline">CIMS(Campco Internal Management System)</h2>
          </v-row>
        </v-card-title>

        <v-card-text class="mt-10">
          <v-form ref="form">
            <!-- FIRST ROW -->
            <h2>Account Information</h2>
            <br />
            <v-row>
              <!-- FIRST COL -->
              <v-col cols="4">
                <v-text-field
                  prepend-icon="mdi-account-outline"
                  label="First Name"
                  placeholder="Juan"
                  class="rounded-xl"
                  v-model="form.first_name"
                  required
                ></v-text-field>
                <address-picker
                  Heading="Current Address"
                  :Fields="address"
                  @extract_address_value="extract_address_value"
                />
                <v-select
                  :items="gender"
                  label="Gender"
                  v-model="form.gender"
                  prepend-icon="mdi-gender-male-female"
                  class="rounded-xl"
                ></v-select>
                <v-select
                  :items="civil_status"
                  v-model="form.civil_status"
                  label="Civil Status"
                  prepend-icon="mdi-account-heart-outline"
                  class="rounded-xl"
                ></v-select>
              </v-col>
              <!-- SECOND COL -->
              <v-col cols="4">
                <v-text-field
                  label="Middle Name"
                  v-model="form.middle_name"
                  placeholder="Torres"
                  class="rounded-xl"
                >
                </v-text-field>
                <v-text-field
                  type="number"
                  prepend-icon="mdi-cellphone"
                  label="Contact Number"
                  v-model="form.contact_number"
                  placeholder=""
                  class="rounded-xl"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-email"
                  label="Email"
                  v-model="form.email"
                  :rules="[rules.email]"
                  placeholder=""
                  class="rounded-xl"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-domain"
                  label="Home Business Occupancy"
                  v-model="form.home_business_occupancy"
                  placeholder=""
                  class="rounded-xl"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-source-commit-start"
                  label="Source of Income"
                  v-model="form.source_of_income"
                  placeholder=""
                  class="rounded-xl"
                ></v-text-field>
                <v-text-field
                  type="number"
                  prepend-icon="mdi-cash"
                  label="Annual Income"
                  v-model="form.annual_income"
                  placeholder=""
                  class="rounded-xl"
                ></v-text-field>
              </v-col>
              <!-- THIRD COL -->
              <v-col cols="4">
                <v-row dense>
                  <v-col cols="9">
                    <v-text-field
                      label="Last Name"
                      v-model="form.last_name"
                      placeholder="Dela Cruz"
                      class="rounded-xl"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Suffix"
                      v-model="form.suffix"
                      placeholder="Jr."
                      class="rounded-xl"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-text-field
                  class="rounded-xl"
                  v-model="form.birth_date"
                  label="Birthdate"
                  prepend-inner-icon="mdi-cake"
                  type="date"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-numeric"
                  v-model="form.tin"
                  label="TIN"
                  placeholder=""
                  class="rounded-xl"
                ></v-text-field>
                <v-text-field
                  label="Occupation"
                  v-model="form.occupation"
                  prepend-icon="mdi-briefcase"
                  placeholder=""
                  class="rounded-xl"
                ></v-text-field>
                <v-text-field
                  label="Company Name"
                  v-model="form.company_name"
                  prepend-icon="mdi-domain"
                  placeholder=""
                  class="rounded-xl"
                ></v-text-field>
                <v-select
                  label="Membership"
                  :items="membership"
                  v-model="form.membership"
                  prepend-icon="mdi-account"
                  placeholder=""
                  class="rounded-xl"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-6 d-flex justify-right">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="accent"
            class="rounded-xl"
            :loading="submit_loading"
            @click="confirm_submit"
            >Submit</v-btn
          >
          <v-btn small color="warning" class="rounded-xl" @click="reset"
            >Reset</v-btn
          >
          <v-btn
            small
            color="grey"
            class="rounded-xl white--text"
            @click="close"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="submit"
        @cancel-event="close_confirm"
      />
    </div>
  </div>
</template>
<script>
import AddressPicker from "../AddressPicker.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    AddressPicker,
    ConfirmNotification: () => import("../ConfirmNotification.vue"),
  },
  data() {
    return {
      address: [],
      submit_loading: false,
      dialog: false,
      alert_message: "Are you sure you want to add this account?",
      show_alert: false,
      gender: ["MALE", "FEMALE"],
      civil_status: ["MARRIED", "SINGLE", "WIDOW/WIDOWER"],
      rules: {
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      membership: ["NOT A MEMBER", "ASSOCIATE", "REGULAR"],
      form: {
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        municipality: "",
        barangay: "",
        purok: "",
        birth_date: "",
        gender: "",
        contact_number: "",
        tin: "0",
        civil_status: "",
        occupation: "",
        annual_income: "0",
        email: "",
        company_name: "",
        source_of_income: "",
        home_business_occupancy: "",
        membership: "ASSOCIATE",
      },
    };
  },
  computed: {
    ...mapGetters({
      account: "account/account",
    }),
  },
  methods: {
    ...mapActions({
      add_account: "account/add_account",
      get_account: "account/get_account",
      edit_address: "dialog/edit_address",
      set_notification: "notification/set_notification",
      change_loading: "dialog/change_loading",
    }),
    extract_address_value(val) {
      this.form.municipality = val[0];
      this.form.barangay = val[1];
      this.form.purok = val[2];
    },
    add() {
      this.edit_address(false);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    confirm_submit() {
      if (
        this.form.first_name == "" ||
        this.form.last_name == "" ||
        this.form.current_address == ""
      ) {
        window.alert("Please fill all Fields");
      } else {
        this.show_alert = true;
        this.submit_loading = true;
      }
    },
    async submit() {
      this.show_alert = false;
      this.change_loading();
      try {
        await this.add_account(this.form);
        this.submit_loading = false;
        await this.get_account(this.account.id);
        this.set_notification({
          message: "You have successfully added a new account",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        this.change_loading();
        this.$router.push("/account/" + this.account.id);
      } catch {
        this.change_loading();
        this.set_notification({
          message: "Connection timeout. Please check your internet connection",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
      }
      this.dialog = false;
    },
    close_confirm() {
      this.show_alert = false;
      this.submit_loading = false;
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style></style>
