<template>
  <v-card v-if="account" tile>
    <v-card-title>
      <v-icon class="mr-2" @click="$router.go(-1)">mdi-arrow-left</v-icon>
      <h2 class="display-1 font-weight-bold primary--text">Account Profile</h2>
    </v-card-title>
    <v-card-text>
      <v-row dense id="accountInformation">
        <v-col lg="7" sm="12" xs="12" id="right_component">
          <account-information :height="right_components_height" />
        </v-col>
        <v-col lg="5" sm="12" xs="12" id="left_component">
          <account-subscriptions
            :height="left_components_height"
            class="mb-2"
          />
          <account-invoices :height="left_components_height" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <payment-history />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    AccountSubscriptions: () =>
      import(
        "@/components/AccountComponents/AccountProfileComponents/AccountSubscriptions.vue"
      ),
    AccountInvoices: () =>
      import(
        "@/components/AccountComponents/AccountProfileComponents/AccountInvoices.vue"
      ),
    PaymentHistory: () =>
      import(
        "@/components/AccountComponents/AccountProfileComponents/PaymentHistory.vue"
      ),
    AccountInformation: () =>
      import(
        "@/components/AccountComponents/AccountProfileComponents/AccountInformation.vue"
      ),
  },
  data() {
    return {
      ready: false,
      selector: "#accountInformation",
      left_components_height: null,
      right_components_height: null,
    };
  },
  computed: {
    ...mapGetters({
      account: "account/account",
      accounts: "account/accounts",
      product_services: "product_service/product_services",
      chart_of_accounts: "chart_of_account/chart_of_accounts",
    }),
  },
  unmounted() {
    let left_height = document.getElementById("left_component").clientHeight;
    let right_height = document.getElementById("right_component").clientHeight;
    if (right_height >= left_height)
      this.left_components_height = (right_height - 16) / 2;
    else this.right_components_height = left_height;
  },
  methods: {
    ...mapActions({
      get_account: "account/get_account",
      get_accounts: "account/get_accounts",
      set_notification: "notification/set_notification",
      edit_account: "account/edit_account",
      change_loading: "dialog/change_loading",
      get_product_services: "product_service/get_product_services",
      get_chart_of_accounts: "chart_of_account/get_chart_of_accounts",
      set_notification: "notification/set_notification",
    }),
  },
  async created() {
    if (!this.account || !this.product_services || !this.chart_of_accounts) {
      this.change_loading();
      try {
        if (!this.account) await this.get_account(this.$route.params.id);
        if (!this.product_services) await this.get_product_services();
        if (!this.chart_of_accounts) await this.get_chart_of_accounts();
        this.ready = true;
        this.change_loading();
      } catch (error) {
        this.change_loading();
        this.set_notification({
          message: "Error: " + error + ", please contact system administrator",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        this.ready = true;
      }
    }
  },
};
</script>
<style scoped>
.picture {
  border-radius: 50%;
  height: 50%;
}
</style>
