<template>
  <v-card flat class="ma-6">
    <view-title title="Accounts Management"/>
    <accounts-table />
  </v-card>
</template>

<script>
import AccountsTable from "@/components/AccountComponents/AccountsTable.vue"
import ViewTitle from "@/components/ViewTitle.vue"
export default {
    components:{
        AccountsTable,
        ViewTitle
    }
}
</script>

<style>

</style>