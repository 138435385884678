import axios from "axios";

export default {
  namespaced: true,
  state: {
    subscriptions: null,
    subscription: null,
    subscription_payment_history: null,
  },
  getters: {
    subscriptions(state) {
      return state.subscriptions;
    },
    subscription(state) {
      return state.subscription;
    },
    subscription_payment_history(state) {
      return state.subscription_payment_history;
    },
  },
  mutations: {
    SET_SUBSCRIPTIONS(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    SET_SUBSCRIPTION(state, subscription) {
      state.subscription = subscription;
    },

    EDIT_SUBSCRIPTION(state, subscription) {
      let id = state.subscriptions.findIndex((a) => {
        return a.id == subscription.id;
      });
      state.subscriptions.splice(id, 1, subscription);
      state.subscription = subscription;
    },
    DELETE_SUBSCRIPTION(state, id) {
      let filtered = state.subscriptions.filter((subscription) => {
        return subscription.id != id;
      });
      state.subscriptions = filtered;
    },
    SET_SUBSCRIPTION_PAYMENT_HISTORY(state, data) {
      state.subscription_payment_history = data;
    },
  },
  actions: {
    async get_subscriptions({ commit }) {
      let response = await axios.get("/subscriptions");
      commit("SET_SUBSCRIPTIONS", response.data);
    },
    async edit_subscription({ commit }, subscription) {
      let response = await axios.put("/edit_subscription", subscription);
      console.log(response.data);
      commit("EDIT_SUBSCRIPTION", response.data);
    },
    async get_subscription({ commit }, id) {
      let response = await axios.get("/subscription/" + id);
      commit("SET_SUBSCRIPTION", response.data);
    },
    set_subscription({ commit }, subscription) {
      commit("SET_SUBSCRIPTION", subscription);
    },
    async get_subscription_payment_history({ commit }, id) {
      let response = await axios.get("/get_subscription_payment_history/" + id);
      console.log(response.data);
      commit("SET_SUBSCRIPTION_PAYMENT_HISTORY", response.data);
    },
  },
};
