import axios from "axios";

// import axios from "axios";
export default {
    namespaced: true,
    state: {
      product_services: null,
      product_service: null,
    },
    getters: {
      product_services(state){
          return state.product_services;
      },
      product_service(state){
        return state.product_service;
    }
    },
    mutations: {
      SET_PRODUCT_SERVICES(state,product_services){
          state.product_services = product_services;
      },
      SET_PRODUCT_SERVICE(state,product_service){
        state.product_service = product_service;
    },
    ADD_PRODUCT_SERVICE(state, product_service) {
      state.product_services.unshift(product_service);
    },
    EDIT_PRODUCT_SERVICE(state,product_service){
        let id = state.product_services.findIndex((a)=>{
            return a.id == product_service.id
          })
          state.product_services.splice(id,1,product_service);
    },
    DELETE_PRODUCT_SERVICE(state,product_service){
      let id = state.product_services.findIndex((a)=>{
          return a.id == product_service.id
        })
        state.product_services.splice(id,1);
  }
    },
    actions: {
      async get_product_services({commit}){
            let response = await axios.get("/products");
          commit("SET_PRODUCT_SERVICES",response.data);
      },
      async add_product_service({commit},product_service){
        let response = await axios.post("/product", product_service);
        commit("ADD_PRODUCT_SERVICE", response.data);
      },
      async edit_product_service({commit},product_service){
        let response = await axios.put("/product", product_service);
        commit("EDIT_PRODUCT_SERVICE",response.data);
      },
      async delete_product_service({commit},id){
        let response = await axios.delete("/product/" + id);
        commit("DELETE_PRODUCT_SERVICE",response.data);
      },
      async set_product_service({commit},product_service){
        commit("SET_PRODUCT_SERVICE",product_service);
      }
    },
  };