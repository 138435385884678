<template>
  <v-card flat class="ma-6">
    <view-title title="Dashboard" />
    <v-card-text>
      <v-row>
        <!-- <v-col cols="4">
          <v-card>
            <line-chart />
          </v-card>
        </v-col> -->
        <v-col cols="12">
          <v-card>
            <sales-chart />
          </v-card>
        </v-col>
        <!-- <v-col cols="4">
          <v-card>
            <doughnut-chart />
          </v-card>
        </v-col> -->
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// import LineChart from "@/components/DashboardComponents/LineChart.vue";
import SalesChart from "@/components/DashboardComponents/SalesChart.vue";
import DoughnutChart from "@/components/DashboardComponents/DoughnutChart.vue";
import ViewTitle from "@/components/ViewTitle.vue";
export default {
  components: {
    // LineChart,
    SalesChart,
    DoughnutChart,
    ViewTitle,
  },
};
</script>

<style></style>
