<template>
  <div>
    <v-btn text class="rounded-xl" color="primary" @click="add">
      <v-icon>mdi-plus</v-icon> new</v-btn
    >
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card class="" elevation="" max-width="">
        <v-sheet color="primary" class="pa-5 white--text">
          <v-row class="pa-2">
            <h2>Add New Chart of Account</h2>

            <v-spacer></v-spacer>
            <h2 class="overline">CIMS(Campco Internal Management System)</h2>
          </v-row></v-sheet
        >

        <v-card-text class="mt-10">
          <v-form ref="form">
            <!-- FIRST ROW -->
            <h1 style="text-align: center">Chart of Accounts</h1>
            <br />
            <v-row>
              <v-col>
                <v-text-field
                  prepend-icon="mdi-account-key"
                  label="Account Code"
                  v-model="form.account_code"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-account-details"
                  label="Account Title"
                  v-model="form.account_title"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-text-field>

                <v-text-field
                  prepend-icon="mdi-cash"
                  label="FS Caption"
                  v-model="form.fs_caption"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-text-field>

                <v-text-field
                  prepend-icon="mdi-message-bulleted"
                  label="Notes to FS"
                  v-model="form.notes_to_fs"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-text-field>

                <v-text-field
                  prepend-icon="mdi-bank-transfer-in"
                  label="Income Statements"
                  v-model="form.income_statement"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-text-field>

                <v-text-field
                  prepend-icon="mdi-comment"
                  label="Remarks"
                  v-model="form.remarks"
                  placeholder=""
                  filled
                  class="rounded-xl"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-right">
          <v-btn
            color="accent"
            class="rounded-xl"
            :loading="submit_loading"
            @click="confirm_submit"
            >Submit</v-btn
          >
          <v-btn color="warning" class="rounded-xl" @click="reset">Reset</v-btn>
          <v-btn color="grey" class="rounded-xl white--text" @click="close"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="submit"
        @cancel-event="close_confirm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ConfirmNotification: () => import("@/components/ConfirmNotification.vue"),
  },
  data() {
    return {
      submit_loading: false,
      alert_message: "Are you sure you want to add this Chart of Account?",
      show_alert: false,
      admission_dialog: false,
      dialog: false,
      form: {
        account_code: "",
        account_title: "",
        fs_caption: "",
        notes_to_fs: "",
        income_statement: "",
        remarks: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      chart_of_accounts: "chart_of_account/chart_of_accounts",
    }),
  },

  methods: {
    ...mapActions({
      add_chart_of_account: "chart_of_account/add_chart_of_account",
      change_loading: "dialog/change_loading",
      set_notification: "notification/set_notification",
    }),
    add() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    confirm_submit() {
      if (
        this.form.account_id == "" ||
        this.form.product_service_id == "" ||
        this.form.payment_type == "" ||
        this.form.date_created == "" ||
        this.form.date_updated == ""
      ) {
        window.alert("Please fill all Fields");
      } else {
        this.show_alert = true;
        this.submit_loading = true;
      }
    },
    async submit() {
      //console.log(this.form);
      this.show_alert = false;
      this.change_loading();
      try {
        await this.add_chart_of_account(this.form);
        this.submit_loading = false;
        this.change_loading();
        this.dialog = false;
        this.set_notification({
          message: "You have successfully added a new chart of account",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
      } catch (error) {
        this.submit_loading = false;
        this.change_loading();
        this.dialog = false;
        this.set_notification({
          message: "Connection timeout. Please check your internet connection",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
      }
    },
    close_confirm() {
      this.show_alert = false;
      this.submit_loading = false;
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style></style>
