import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"ma-6",attrs:{"flat":""}},[_c('view-title',{attrs:{"title":"Report Management"}}),_c(VCard,[_c(VCardText,[_c(VTabs,{attrs:{"left":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTab,{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTabItem,{key:item},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[(_vm.tab == 0)?_c('invoices-report'):(_vm.tab == 1)?_c('bills-report'):(_vm.tab == 2)?_c('journal-report'):(_vm.tab == 3)?_c('statement-of-financial-condition'):_c('trial-balance-report')],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }