<template>
  <v-card flat class="ma-6">
    <view-title title="Payments Management" />
    <payments-table />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    PaymentsTable: () =>
      import("@/components/PaymentComponents/PaymentsTable.vue"),
  },
  computed: {
    ...mapGetters({
      add_payment_dialog: "dialog/add_payment_dialog",
    }),
  },
};
</script>

<style></style>
