<template>
  <v-card flat class="ma-6">
    <view-title title="Products and Services Management"/>
    <product-services-table />
  </v-card>
</template>

<script>
import ProductServicesTable from "@/components/ProductServiceComponents/ProductServicesTable.vue"
import ViewTitle from "@/components/ViewTitle.vue"
export default {
    components:{
        ProductServicesTable,
        ViewTitle
    }
}
</script>

<style>

</style>