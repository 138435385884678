import axios from "axios";

// import axios from "axios";
export default {
  namespaced: true,
  state: {
    invoices: null,
    invoice: null,
    last_invoice_num: null,
  },
  getters: {
    invoices(state) {
      return state.invoices;
    },
    invoice(state) {
      return state.invoice;
    },
    last_invoice_num(state) {
      return state.last_invoice_num;
    },
  },
  mutations: {
    SET_INVOICES(state, invoices) {
      state.invoices = invoices;
    },
    SET_INVOICE(state, invoice) {
      state.invoice = invoice;
    },
    ADD_INVOICE(state, invoice) {
      state.invoices.unshift(invoice);
    },
    EDIT_INVOICE(state, invoice) {
      let id = state.invoices.findIndex((a) => {
        return a.id == invoice.id;
      });
      state.invoices.splice(id, 1, invoice);
      state.invoice = invoice;
    },
    DELETE_INVOICE(state, id) {
      let filtered = state.invoices.filter((invoice) => {
        return invoice.id != id;
      });
      state.invoices = filtered;
    },
    ADD_SUBSCRIPTION_INVOICES(state, invoices) {
      invoices.forEach((invoice) => {
        state.invoices.unshift(invoice);
      });
    },
    SET_LAST_INVOICE_NUM(state, invoice_num) {
      state.last_invoice_num = invoice_num;
    },
    // DELETE_INVOICE_ITEM(state, invoice) {
    //   let id = state.invoices.findIndex((a) => {
    //     return a.id == invoice.id;
    //   });
    //   state.invoices.splice(id, 1, invoice);
    //   state.invoice = invoice;
    // },
  },
  actions: {
    async get_invoices({ commit }) {
      let response = await axios.get("/invoices");
      console.log(response.data);
      commit("SET_INVOICES", response.data);
    },
    async get_invoice({ commit }, id) {
      let response = await axios.get("/invoice/" + id);
      commit("SET_INVOICE", response.data);
    },
    async edit_invoice({ commit }, invoice) {
      let response = await axios.put("/invoice", invoice);
      commit("EDIT_INVOICE", response.data);
    },
    async delete_invoice({ commit }, id) {
      await axios.delete("/invoice/" + id);
      commit("DELETE_INVOICE", id);
    },
    async restore_invoice({ commit }, id) {
      await axios.put("/restore/" + id);
      commit("ADD_INVOICE", response.data);
    },
    async add_invoice({ commit }, invoice) {
      let response = await axios.post("/invoice", invoice);
      commit("ADD_INVOICE", response.data);
    },
    async add_invoice_items({ commit }, item) {
      let response = await axios.post("/add_invoice_items", item);
      commit("EDIT_INVOICE", response.data);
    },
    async add_invoice_payment({ commit }, payment) {
      let response = await axios.post("/add_invoice_payment", payment);
      commit("EDIT_INVOICE", response.data);
    },
    async delete_invoice_item({ commit }, request) {
      let response = await axios.delete(
        "/delete_invoice_item/" + request.invoice_id + "/" + request.item_id
      );
      commit("EDIT_INVOICE", response.data);
    },
    async delete_invoice_payment({ commit }, request) {
      let response = await axios.delete(
        "/delete_invoice_payment/" +
          request.invoice_id +
          "/" +
          request.payment_id
      );
      commit("EDIT_INVOICE", response.data);
    },
    async add_subscription_invoices({ commit }, invoices) {
      commit("ADD_SUBSCRIPTION_INVOICES", invoices);
    },
    async get_last_invoice_num() {
      let response = await axios.get("/get_last_invoice_num");
      console.log(response.data);
      return response.data;
    },
  },
};
