<template>
  <div>
    <h3>
      {{ Heading }}
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            @click="show_address_edit"
            class="pb-1"
            small
            :color="!address_picker ? 'red' : 'blue'"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{ !address_picker ? "mdi-close" : "mdi-pencil" }}
          </v-icon>
        </template>
        <span v-if="address_picker">click to edit the address</span>
        <span v-else>cancel edit</span>
      </v-tooltip>
    </h3>
    <div v-if="address_picker">
      <v-text-field
        prepend-icon="mdi-town-hall"
        label="Municipality"
        v-model="Fields[0]"
        disabled
      ></v-text-field>
      <v-text-field
        prepend-icon="mdi-map-marker-outline"
        label="Barangay"
        v-model="Fields[1]"
        disabled
      ></v-text-field>
      <v-text-field
        prepend-icon="mdi-map-marker"
        label="Purok"
        v-model="Fields[2]"
        disabled
      ></v-text-field>
    </div>
    <div v-else>
      <v-select
        prepend-icon="mdi-town-hall"
        label="Municipality"
        :items="municipalities_arr"
        item-text="city_name"
        item-value="city_name"
        v-model="municipality"
        @input="get_barangays"
      ></v-select>
      <v-select
        prepend-icon="mdi-map-marker-outline"
        :disabled="!municipality"
        label="Barangay"
        :items="barangays_arr"
        item-text="brgy_name"
        item-value="brgy_name"
        v-model="barangay"
        @input="enable_purok = true"
      ></v-select>
      <v-text-field
        prepend-icon="mdi-map-marker"
        :disabled="!enable_purok"
        type="text"
        label="Purok"
        v-model="purok"
        class="rounded-xl"
        @input="extract_address_value"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import barangays from "@/components/jsonfiles/barangays.json";
import provinces from "@/components/jsonfiles/provinces.json";
import cities from "@/components/jsonfiles/cities.json";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      enable_purok: false,
      barangays: barangays,
      provinces: provinces,
      cities: cities,
      municipality: null,
      barangay: null,
      purok: null,
      barangays_arr: null,
      municipalities_arr: null,
    };
  },
  computed: {
    ...mapGetters({
      address_picker: "dialog/address_picker",
    }),
  },
  props: {
    Heading: String,
    Fields: Array,
  },
  created() {
    this.get_cities();
    if (this.address_picker) {
      this.municipality = this.Fields[0];
      this.barangay = this.Fields[1];
      this.purok = this.Fields[2];
    }
  },
  methods: {
    ...mapActions({
      edit_address: "dialog/edit_address",
    }),
    show_address_edit() {
      this.edit_address();
    },
    get_cities() {
      var city = this.cities.filter((c) => {
        return c.province_code == "1668";
      });
      this.municipalities_arr = city;
    },

    extract_city_code(city_name) {
      // extract city code from city name
      var city = this.cities.filter((c) => {
        return c.city_name == city_name;
      });
      var c = city[0];
      // var city_code = city_code.city_code;
      return c.city_code;
    },
    get_barangays() {
      this.purok = null;
      var city_code = this.extract_city_code(this.municipality);
      var barangays = this.barangays.filter((b) => {
        return b.city_code == city_code;
      });
      this.barangays_arr = barangays;
    },
    extract_address_value() {
      console.log(this.municipality);
      var address_arr = [this.municipality, this.barangay, this.purok];
      this.$emit("extract_address_value", address_arr);
    },
  },
};
</script>

<style></style>
