import axios from "axios";

export default {
  namespaced: true,
  state: {
    notifications: null,
  },
  getters: {
    notifications(state) {
      return state.notifications;
    },
  },
  mutations: {
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications;
    },
  },
  actions: {
    async get_notifications({ commit }) {
      let response = await axios.get("/notifications");
      commit("SET_NOTIFICATIONS", response.data);
    },
  },
};
