<template>
  <div class="mx-auto text-center" max-width="600">
    <v-card-text>
      <v-row justify="center" align="center">
        <v-col cols="10">
          <v-sheet>
            <v-sparkline
              fill
              :value="value"
              color="primary"
              padding="24"
              stroke-linecap="round"
              :smooth="false"
              show-labels
              :labels="labels"
              :radius="false"
            >
              <!-- <template v-slot:label="item"> ${{ item.value }} </template> -->
            </v-sparkline>
          </v-sheet>
        </v-col>
        <v-col>
          <v-card color="primary" dark>
            <v-card-title
              >Active
              <v-spacer></v-spacer>
              <v-icon large>mdi-lan-connect</v-icon>
            </v-card-title>
            <v-card-text v-if="subscriptions">
              <span class="text-h4">{{ get_active().length }}</span>
            </v-card-text>
          </v-card>
          <br />
          <v-card color="red" dark>
            <v-card-title
              >Inactive
              <v-spacer></v-spacer>
              <v-icon large>mdi-lan-disconnect</v-icon>
            </v-card-title>
            <v-card-text v-if="subscriptions">
              <span class="text-h4">{{ get_inactive().length }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="text-h4 font-weight-thin">Collection This Year</div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="justify-center">
      <v-btn block text> Go to Report </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      value: [423, 446, 675, 510, 590, 610, 760, 858, 748, 925, 400, 878],
    };
  },
  computed: {
    ...mapGetters({
      subscriptions: "subscription/subscriptions",
    }),
  },
  methods: {
    ...mapActions({
      get_subscriptions: "subscription/get_subscriptions",
      change_loading: "dialog/change_loading",
    }),
    get_active() {
      let active = this.subscriptions.filter((s) => {
        return s.status == "ACTIVE";
      });
      return active;
    },
    get_inactive() {
      let inactive = this.subscriptions.filter((s) => {
        return s.status == "INACTIVE";
      });
      return inactive;
    },
  },
  async created() {
    if (!this.subscriptions) {
      this.change_loading();
      await this.get_subscriptions();
      this.change_loading();
    }
  },
};
</script>

<style></style>
