import axios from "axios";

// import axios from "axios";
export default {
  namespaced: true,
  state: {
    accounts: null,
    account: null,
    account_payables: null,
  },
  getters: {
    accounts(state) {
      return state.accounts;
    },
    account(state) {
      return state.account;
    },
    account_payables(state) {
      return state.account_payables;
    },
  },
  mutations: {
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },
    SET_ACCOUNT(state, account) {
      state.account = account;
    },
    SET_ACCOUNT_PAYABLES(state, account) {
      state.account_payables = account;
    },
    ADD_ACCOUNT(state, account) {
      state.accounts.unshift(account);
      state.account = account;
    },
    EDIT_ACCOUNT(state, account) {
      state.account = account;
      let id = state.accounts.findIndex((a) => {
        return a.id == account.id;
      });
      state.accounts.splice(id, 1, account);
    },
    DELETE_ACCOUNT(state, id) {
      let filtered = state.accounts.filter((a) => {
        return a.id != id;
      });
      state.accounts = filtered;
    },
    CLEAR_ACCOUNT(state) {
      state.account = null;
    },
    DELETE_SUBSCRIPTION(state, subscription_id) {
      let filtered = state.account.subscriptions.filter((x) => {
        return x.id != subscription_id;
      });
      state.account.subscriptions = filtered;
    },
  },
  actions: {
    async get_accounts({ commit }) {
      let response = await axios.get("/accounts");
      commit("SET_ACCOUNTS", response.data);
    },
    //ACCOUNT
    async get_account({ commit }, id) {
      let response = await axios.get("/account/" + id);
      commit("SET_ACCOUNT", response.data);
    },
    async add_account({ commit }, account) {
      let response = await axios.post("/account", account);
      commit("ADD_ACCOUNT", response.data);
    },
    async edit_account({ commit }, account) {
      let response = await axios.put("/account", account);
      commit("EDIT_ACCOUNT", response.data);
    },
    async delete_account({ commit }, id) {
      let response = await axios.delete("/account/" + id);
      commit("DELETE_ACCOUNT", response.data);
    },
    //
    //ACCOUNT SUBSCRIPTION
    async add_subscription({ commit }, subscription) {
      let response = await axios.post("/add_subscription", subscription);
      commit("SET_ACCOUNT", response.data);
    },
    async delete_subscription({ commit, state }, id) {
      let response = await axios.delete(
        "/subscription/" + state.account.id + "/" + id
      );
      commit("DELETE_SUBSCRIPTION", response.data);
    },
    async change_subscription_status({ commit }, request) {
      let response = await axios.put("/change_subscription_status", request);
      commit("SET_ACCOUNT", response.data);
    },
    async add_subscription_payment({ commit }, request) {
      let response = await axios.post("/add_subscription_payment", request);
      commit("EDIT_ACCOUNT", response.data);
    },
    //
    //ACCOUNT INVOICE
    async add_invoice({ commit }, invoice) {
      let response = await axios.post("/add_invoice", invoice);
      commit("SET_ACCOUNT", response.data);
    },
    async add_invoice_items({ commit }, item) {
      let response = await axios.post("/add_invoice_items", item);
      commit("SET_ACCOUNT", response.data);
    },
    async delete_invoice_item({ commit }, request) {
      let response = await axios.post("/delete_invoice_item", request);
      commit("EDIT_ACCOUNT", response.data[0]);
      commit("SET_ACCOUNT", response.data[1]);
    },
    //
    clear_account({ commit }) {
      commit("CLEAR_ACCOUNT");
    },
  },
};
