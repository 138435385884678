<template>
  <v-app>
    <side-nav :mini_variant="mini_variant" v-if="authenticated" />
    <top-nav
      @toggle_mini_variant="mini_variant = !mini_variant"
      v-if="authenticated"
    />
    <loading />
    <notification />

    <v-main>
      <router-view />
    </v-main>

    <!-- <v-lazy>
      <v-btn
        fixed
        color="primary"
        dark
        right
        bottom
        fab
        small
        @click="$vuetify.goTo(1)"
        ><v-icon>mdi-arrow-up</v-icon></v-btn
      >
    </v-lazy> -->
  </v-app>
</template>

<script>
import Notification from "./components/Notification.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      mini_variant: false,
      isIntersecting: false,
    };
  },
  components: {
    SideNav: () => import("@/components/SideNav.vue"),
    TopNav: () => import("@/components/TopNav.vue"),
    Notification,
    Loading: () => import("@/components/Loading.vue"),
    // PushNotifications: () => import("@/components/PushNotification.vue"),
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      notifications: "push_notification/notifications",
    }),
  },
  methods: {
    ...mapActions({
      change_loading: "dialog/change_loading",
      get_account: "account/get_account",
    }),
    onIntersect(entries, observer) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].isIntersecting;
    },
  },
  //LISTEN TO SERVER EVENTS
  // mounted() {
  //   window.Echo.channel("channel").listen("AddAccount", (e) => {
  //     console.log("hey");
  //     console.log(e);
  //   });
  // },
};
</script>
<style scoped></style>
