import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/storage";
import SearchBar from "@/components/SearchBar.vue";
import ViewTitle from "@/components/ViewTitle.vue";
import VueHtmlToPaper from "vue-html-to-paper";

// import Echo from "laravel-echo";
// import Pusher from "pusher-js";
// window.Pusher = Pusher;
// window.Pusher = require("pusher-js");

// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: process.env.VUE_APP_WEBSOCKETS_KEY,
//   cluster: process.env.VUE_APP_WEBSOCKETS_CLUSTER,
//   wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
//   wsPort: 6001,
//   forceTLS: false,
//   disableStats: true,
// });

const options = {
  styles: [
    "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
    "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
    vuetify,
  ],
};
// Your web app's Firebase configuration

var firebaseConfig = {
  apiKey: "AIzaSyCCFAnbgbWZgzhVao0PWQ5Sutl7ySAiumY",
  authDomain: "campco-cims.firebaseapp.com",
  projectId: "campco-cims",
  storageBucket: "campco-cims.appspot.com",
  messagingSenderId: "12155837044",
  appId: "1:12155837044:web:6e770c0de37a3625878f21",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;
Vue.component("SearchBar", SearchBar);
Vue.component("ViewTitle", ViewTitle);
Vue.use(VueHtmlToPaper, options);
axios.defaults.baseURL = "https://campco-server.kpcms.online/api";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    moment,

    // iconfont: 'mdi',
    render: (h) => h(App),
  }).$mount("#app");
});
