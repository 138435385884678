import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.product_services)?_c(VCard,{staticClass:"pa-6"},[_c(VCardTitle,[_c('SearchBar',{attrs:{"searchClass":_vm.search_class},on:{"searching":function($event){return _vm.searching($event)}}}),_c(VSpacer),_c('add-product-service')],1),_c(VDataTable,{attrs:{"dense":"","headers":_vm.headers,"items":_vm.product_services,"items-per-page":10,"search":_vm.keyword},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c(VRow,[_c(VIcon,{staticClass:"mx-1",attrs:{"color":"blue"},on:{"click":function($event){return _vm.edit_product_service(item)}}},[_vm._v("mdi-pencil")]),_c(VIcon,{staticClass:"mx-1",attrs:{"color":"red"},on:{"click":function($event){return _vm.confirm_delete(item.id)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)}),_c('edit-product-service'),(_vm.show_alert)?_c('div',[_c('confirm-notification',{staticClass:"alert",attrs:{"icon":"mdi-alert-outline","type":"warning","message":this.alert_message},on:{"confirm-event":_vm.submit,"cancel-event":_vm.close_confirm}})],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }