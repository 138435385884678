import axios from "axios";

// import axios from "axios";
export default {
    namespaced: true,
    state: {
      keyword: "",
    },
    getters: {
        keyword(state){
            return state.keyword
        }
    },
    mutations: {
        SET_KEYWORD(state,keyword){
            state.keyword = keyword
        }
    },
    actions: {
        searching({commit},keyword){
            commit("SET_KEYWORD",keyword)
        }
    },
  };