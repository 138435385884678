import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.account)?_c(VCard,{attrs:{"tile":""}},[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("mdi-arrow-left")]),_c('h2',{staticClass:"display-1 font-weight-bold primary--text"},[_vm._v("Account Profile")])],1),_c(VCardText,[_c(VRow,{attrs:{"dense":"","id":"accountInformation"}},[_c(VCol,{attrs:{"lg":"7","sm":"12","xs":"12","id":"right_component"}},[_c('account-information',{attrs:{"height":_vm.right_components_height}})],1),_c(VCol,{attrs:{"lg":"5","sm":"12","xs":"12","id":"left_component"}},[_c('account-subscriptions',{staticClass:"mb-2",attrs:{"height":_vm.left_components_height}}),_c('account-invoices',{attrs:{"height":_vm.left_components_height}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c('payment-history')],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }