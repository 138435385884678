<template>
  <v-card flat class="pa-6" v-if="subscriptions">
    <v-card-title>
      <SearchBar :searchClass="search_class" @searching="searching($event)" />
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            color="primary"
            @click="print_subscriptions"
            v-bind="attrs"
            v-on="on"
            ><v-icon class="ml-2">mdi-chart-bar</v-icon></v-btn
          >
        </template>
        <span>Report</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :search="keyword"
        :items="filtered_subscriptions"
        :headers="headers"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ format_date(item.created_at) }}
        </template>
        <template v-slot:[`item.logs`]="{ item }">
          <v-icon small @click="view_logs(item)">mdi-list-status</v-icon>
        </template>
        <template v-slot:[`item.product`]="{ item }">
          {{ item.invoices[0].products[0].item_name }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            small
            dark
            :color="item.status == 'ACTIVE' ? 'primary' : 'red'"
            >{{ item.status }}</v-chip
          >
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon class="mx-1" color="primary">mdi-account</v-icon> -->
          <v-icon class="mx-1" color="blue" @click="edit(item)"
            >mdi-pencil</v-icon
          >
          <v-icon class="mx-1" color="red" @click="confirm_delete(item.id)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>
    <edit-subscription v-if="edit_subscription_dialog" />
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="submit"
        @cancel-event="close_confirm"
      />
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["filter"],
  components: {
    ConfirmNotification: () => import("../ConfirmNotification.vue"),
    EditSubscription: () => import("./EditSubscription.vue"),
  },
  data() {
    return {
      show_alert: false,
      search_class: "",
      keyword: "",
      headers: [
        {
          text: "Logs",
          value: "logs",
        },

        {
          text: "Account Name",
          value: "account.full_name",
        },
        {
          text: "Product",
          value: "product",
        },
        {
          text: "Amount",
          value: "invoices[0].amount",
        },
        {
          text: "Remarks",
          value: "remarks",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      subscriptions: "subscription/subscriptions",
      subscription: "subscription/subscription",
      edit_subscription_dialog: "dialog/edit_subscription_dialog",
      accounts: "account/accounts",
    }),
    filtered_subscriptions() {
      let filtered = null;
      if (this.filter == "") filtered = this.subscriptions;
      else
        filtered = this.subscriptions.filter((i) => {
          return i.status == this.filter;
        });
      return filtered;
    },
  },
  methods: {
    ...mapActions({
      change_add_subscription_dialog: "dialog/change_add_subscription_dialog",
      change_edit_subscription_dialog: "dialog/change_edit_subscription_dialog",
      change_subscription_info_dialog: "dialog/change_subscription_info_dialog",
      set_subscription: "subscription/set_subscription",
      get_subscription: "subscription/get_subscription",
      delete_subscription: "subscription/delete_subscription",
      change_loading: "dialog/change_loading",
      set_notification: "notification/set_notification",
    }),
    print_subscriptions() {
      console.log(this.filtered_subscriptions);
    },
    async view_subscription(item) {
      this.change_loading();
      await this.get_subscription(item.id);
      this.change_loading();
      this.change_subscription_info_dialog();
    },
    searching(item) {
      this.keyword = item;
    },
    async edit(subscription) {
      this.set_subscription(subscription);
      this.change_edit_subscription_dialog();
    },
    confirm_delete(id) {
      console.log(id);
      // this.subscription_id = id;
      // this.show_alert = true;
    },
    convert_currency(amount) {
      let peso = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
      return peso;
    },
    format_date(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    async submit() {
      this.show_alert = false;
      this.change_loading();
      try {
        await this.delete_subscription(this.subscription_id);
        this.set_notification({
          message: "You have successfully deleted an subscription",
          color: "primary",
          showing: true,
          icon: "mdi-check",
        });
      } catch (error) {
        this.set_notification({
          message: "Error: " + error + "; Please contact system administrator",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
      }
      this.change_loading();
    },
    add() {
      this.change_add_subscription_dialog();
    },
    close_confirm() {
      this.show_alert = false;
    },
  },
};
</script>

<style></style>
