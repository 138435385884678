<template>
  <v-card class="py-4" v-if="invoice">
    <v-card-text class="pa-4 mt-n4">
      <v-row dense>
        <v-col >
          <invoice-information @set_selected_products="set_selected_products" ref="clear_selected_products" />
        </v-col>

        <!-- <v-col cols="5" class="d-print-none">
          <add-payment @clear_selected_products="clear" :selected_products="selected_products" />
        </v-col> -->
      </v-row>
    </v-card-text>
    <add-invoice-item />
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    // AddPayment: () =>
    //   import(
    //     "@/components/InvoiceComponents/InvoiceProfileComponents/AddPayment.vue"
    //   ),
    InvoiceInformation: () =>
      import(
        "@/components/InvoiceComponents/InvoiceProfileComponents/InvoiceInformation.vue"
      ),
    AddInvoiceItem: ()=>import("@/components/InvoiceComponents/InvoiceProfileComponents/AddInvoiceItem.vue")
  },
  data() {
    return {
      selected_products: [],
    };
  },
  computed: {
    ...mapGetters({
      invoice: "invoice/invoice",
      invoices: "invoice/invoices",
       product_services: "product_service/product_services",
       chart_of_accounts: "chart_of_account/chart_of_accounts"
    }),
  },
  methods: {
    ...mapActions({
      change_loading: "dialog/change_loading",
      set_notification: "notification/set_notification",
      get_invoice: "invoice/get_invoice",
      get_invoices: "invoice/get_invoices",
      get_product_services: "product_service/get_product_services",
      get_chart_of_accounts: "chart_of_account/get_chart_of_accounts"
    }),
    set_selected_products(items) {
      this.selected_products = items;
    },
    clear(){
      this.selected_products = [];
      this.$refs.clear_selected_products.clear_selected_products();
    }
  },
  async created() {
    if (!this.invoices || !this.invoice || !this.product_services || !this.chart_of_accounts) {
      this.change_loading();
      try {
        if(!this.invoice) await this.get_invoice(this.$route.params.id);
        if(!this.invoices) await this.get_invoices();
        if(!this.product_services) await this.get_product_services();
        if(!this.chart_of_accounts) await this.get_chart_of_accounts();
  
      } catch (error) {
        this.set_notification({
          message: "Error: " + error + "; Please contact system administrator",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
        
      }
      this.change_loading();
    }
  },
  
};
</script>
